<template>
  <div class="mb-5" v-if="assessment">
    <h1 class="text-xl text-grey-light font-medium font-headers">
      Results /
      <span class="font-bold text-grey-dark-1">Assessment Overview</span>
    </h1>
    <div class="mt-3 flex items-center justify-between">
      <div class="w-1/4">
        <Select
          :options="sortedAssessments"
          :onSelect="setValue"
          :value="selectedAssessment"
          label="Assessment Name"
        />
      </div>
      <div v-if="assessment.canDownload">
        <Button
          text="Download"
          type="primary"
          size="medium"
          :iconLeft="downloadIcon"
          @click.native="downloadPdf"
        />
      </div>
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded">
      <div class="flex flex-row flex-wrap gap-y-2 gap-x-5 xl:gap-x-11">
        <div>
          <p class="text-sm-2 text-grey-light">Assessment Name</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4 max-w-300" style="text-wrap: nowrap;">
            {{ assessment.title }}
          </p>
        </div>
        <div>
          <p class="text-sm-2 text-grey-light">Launch Date</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4" style="text-wrap: nowrap;">
            {{ assessment.launchedOn }}
          </p>
        </div>
        <div>
          <p class="text-sm-2 text-grey-light">Completion Date</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4" style="text-wrap: nowrap;">
            {{ assessment.completedOn }}
          </p>
        </div>
        <div>
          <p class="text-sm-2 text-grey-light">Close Date</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4" style="text-wrap: nowrap;">
            {{ assessment.dueOn }}
          </p>
        </div>
      </div>
      <div class="flex flex-row mt-3">
        <div>
          <p class="text-sm-2 text-grey-light">About Your Results</p>
          <p class="text-grey-dark-1 text-md mt-1 w-1/2 pr-4">
            The Assessment is based on a comprehensive competency-based
            capability model comprised of core competencies and sub-competencies
            for high-performing practitioners worldwide.
          </p>
          <div class="text-grey-dark-1 text-md mt-4 w-1/2 pr-4">
            <p>Competency Level</p>
            <div class="mt-1 flex flex-wrap">
              <p
                v-for="(item) in competencyLevels"
                :key="item.score"
                class="mr-3"
              >
                <span class="font-bold">{{ item.score }}.0</span>
                = {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded mt-2" v-if="competencyResults">
      <h2 class="text-grey-light text-mdl font-bold font-headers mt-1">
        Your Results and Targets for Competencies
      </h2>
      <div class="mt-2">
        <LineBarChart
          :categories="competencyResults.categories"
          :barData="competencyResults.barData"
          :lineData="competencyResults.lineData"
          :plotSpace="60"
          height="450"
          :yAxisMaxValue="yAxisMaxValue"
          :numDivLines="numDivLines"
          id="competency-chart"
        />
      </div>
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded mt-2" v-if="competencyResults">
      <DeltaTable
        type="competency"
        :tableData="competencyResults.tableData"
        title="Overall Results by Core Competency"
        :onSortingChange="onCompetenciesSortingChange"
      />
    </div>
    <div
      class="bg-white shadow mt-3 p-3 rounded mt-2"
      v-if="subcompetencyResults"
    >
      <h2 class="text-grey-light text-mdl font-bold font-headers mt-1">
        Your Results and Targets for Sub-Competencies
      </h2>
      <div class="mt-2">
        <LineBarChart
          :categories="subcompetencyResults.categories"
          :barData="subcompetencyResults.barData"
          :lineData="subcompetencyResults.lineData"
          :plotSpace="15"
          height="450"
          :yAxisMaxValue="yAxisMaxValue"
          :numDivLines="numDivLines"
          :hideLegend="true"
          id="subcompetency-chart"
        />
        <StaticLegend class="mt-3 ml-2" :legend="legend" />
      </div>
    </div>
    <div
      class="bg-white shadow mt-3 p-3 rounded mt-2"
      v-if="subcompetencyResults"
    >
      <DeltaTable
        type="subcompetency"
        :tableData="subcompetencyResults.tableData"
        title="Overall Results by Core Sub-Competency"
        :onSortingChange="onSubCompetenciesSortingChange"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import DeltaTable from "@/components/DeltaTable/DeltaTable";
import StaticLegend from "@/components/Charts/StaticLegend.vue";
import FusionCharts from "fusioncharts";
import downloadIcon from "@/assets/img/icons/download.svg";
import scrollToTop from "../../../services/utils/scrollToTop";

export default {
  name: "AssessmentResult",
  components: {
    DeltaTable,
    StaticLegend,
  },
  data: () => ({
    selectedAssessment: null,
    downloadIcon,
    competencySorting: {
      column: "",
      direction: 1,
    },
    subcompetencySorting: {
      column: "",
      direction: 1,
    },
  }),
  computed: {
    ...mapState({
      assessments: (state) => state.results.assessments,
      assessment: (state) => state.results.assessment,
      legend: (state) => state.results.subcompetencyLegend,
      competencyResults: (state) => state.results.competencyResults,
      subcompetencyResults: (state) => state.results.subcompetencyResults,
    }),
    isIndividual() {
      return this.assessment?.surveyType === 0;
    },
    isOrganizational() {
      return this.assessment?.surveyType === 1;
    },
    yAxisMaxValue() {
      return this.isIndividual ? "4" : "5";
    },
    numDivLines() {
      return this.isIndividual ? "7" : "9";
    },
    sortedAssessments() {
      const sortedArray = this.assessments.sort(
        (a, b) => +moment(b.completedAt) - +moment(a.completedAt)
      );
      return sortedArray;
    },
    competencyLevels() {
      return this.assessment.competencyLevels;
    },
  },
  watch: {
    $route(_, from) {
      if (from.name === "AssessmentResult") {
        this.init();
      } else {
        this.fetchResults();
      }
    },
    selectedAssessment(newVal, oldVal) {
      if (
        oldVal &&
        oldVal.id !== newVal.id &&
        newVal.id !== this.$route.params.id
      ) {
        this.$router.push({
          name: "AssessmentResult",
          params: { id: newVal.id },
        });
      }
    },
  },
  methods: {
    ...mapActions({
      getSurveys: "results/getSurveys",
      download: "results/downloadPdf",
      getAssessment: "results/getAssessment",
      getAssessmentCompetencyResults: "results/getAssessmentCompetencyResults",
      getAssessmentSubcompetencyResults:
        "results/getAssessmentSubcompetencyResults",
    }),
    setValue(val) {
      this.selectedAssessment = val;
    },
    fetchResults() {
      this.getAssessmentCompetencyResults({ id: this.$route.params.id });
      this.getAssessmentSubcompetencyResults({ id: this.$route.params.id });
    },
    exportChart(chartName) {
      const chart = FusionCharts(chartName);
      var svg = chart.getSVGString();
      svg = svg.replace(/&nbsp;/g, "&#160;");
      return window.btoa(unescape(encodeURIComponent(svg)));
    },
    downloadPdf() {
      const competencyImg = this.exportChart("competency-chart");
      const subcompetencyImg = this.exportChart("subcompetency-chart");
      this.download({
        id: this.$route.params.id,
        competencyImg,
        subcompetencyImg,
        competencySorting: this.competencySorting,
        subcompetencySorting: this.subcompetencySorting,
      });
    },
    onCompetenciesSortingChange (sortingColumn, sortingDirection) {
      this.competencySorting = {
        column: sortingColumn,
        direction: sortingDirection,
      }
    },
    onSubCompetenciesSortingChange (sortingColumn, sortingDirection) {
      this.subcompetencySorting = {
        column: sortingColumn,
        direction: sortingDirection,
      }
    },
    async init() {
      await this.getSurveys();
      await this.getAssessment({ id: this.$route.params.id });
      this.setValue({
        id: this.assessment.id,
        label: this.assessment.title,
      });
      this.fetchResults();
    },
  },
  async mounted() {
    scrollToTop();
    await this.init();
  },
};
</script>

<style></style>
